
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import BigNumber from 'bignumber.js'  
import { ChakraProvider } from '@chakra-ui/react'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import type { AppProps } from 'next/app'
import { RecoilRoot } from 'recoil'
import { customTheme } from '@/utils/Theme'
import { CommonDataFetcher } from '@/components/Common/CommonDataFetcher'
import { squidUrl } from '@/constants/squidUrl'
import Head from 'next/head'

// prevent number formatting issue
BigNumber.config({
  EXPONENTIAL_AT: 1000, 
  DECIMAL_PLACES: 80, 
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: squidUrl
});

function MyApp({ Component, pageProps }: AppProps) {

  return (
    <>
      <Head>
        <title>ApolloSwap</title>
      </Head>
      <ChakraProvider theme={customTheme}>
        <ApolloProvider client={client}>
          <RecoilRoot>
            <CommonDataFetcher />
            <Component {...pageProps} />
          </RecoilRoot>
        </ApolloProvider>
      </ChakraProvider>
    </>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  