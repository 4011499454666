import { ApiPromise, WsProvider } from '@polkadot/api'
import { useCallback, useEffect, useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { wsProviderByChainId } from '@/constants/wsProviderInfo'
import { currentAPI, useChainIdByProvider } from 'state/wsProvider'
import { useSubscribed } from 'state/blockHeaderSubscription'


export const useFetchApi = () => {
  const [api, setAPI] = useRecoilState(currentAPI)
  const chainId = useChainId()
  const [,setIsSubscribed] = useSubscribed()

  const getApi = useCallback(async () => {
    if (!chainId) { return }
    const provider = new WsProvider(wsProviderByChainId[chainId])
    const _api = await ApiPromise.create({ provider })
    // Trigger block subscription upon api change
    if (api) setIsSubscribed(false)
    setAPI(_api)
  }, [setAPI, chainId])

  useEffect(() => {
    getApi()
  }, [getApi])

  return useMemo(() => api, [api])
}

export const useApi = () => {
  const [api] = useRecoilState(currentAPI)
  return useMemo(() => api, [api])
}

export const useChainId = (): number | undefined | null => {
  const [chainId] = useChainIdByProvider()
  return useMemo(() => chainId, [chainId])
}
