import type { VoidFn } from '@polkadot/api/types'
import { atom, useRecoilState } from 'recoil'
import type { SetterOrUpdater } from 'recoil'

export const isSubscribed = atom<boolean>({ 
  key: 'isSubscribed',
  default: false,
})

export const unsub = atom<VoidFn>({
  key: 'unsub',
  default: () => {},
})

type Result = [boolean, (arg: boolean) => void]

export const useSubscribed = (): Result => {
  const [isSubscribedBlock, setIsSubscribed] = useRecoilState(isSubscribed)

  return [
    isSubscribedBlock,
    setIsSubscribed
  ]
}

export const useUnsub = (): [VoidFn, SetterOrUpdater<VoidFn>] => {
  const [_unsub, setUnsub] = useRecoilState(unsub)

  return [
    _unsub,
    setUnsub
  ]
}
