import { useCallback, useEffect } from 'react'
import { useApi } from '@/hooks/useApi'
import { useSubscribed, useUnsub } from 'state/blockHeaderSubscription'
import { useBlockNumber } from 'state/blockNumber'

export const useSubscribeBlockNumber = () => {
  const api = useApi()
  const [isSubscribed, setIsSubscribed] = useSubscribed()
  const [,setBlockNumber] = useBlockNumber()
  const [unsub, setUnsub] = useUnsub()

  const listenBlockHeader = useCallback(async () => {
    if (!api || isSubscribed) {
      return
    }
    console.log('subscription started')
    setIsSubscribed(true)
    const _unsub = await api.rpc.chain.subscribeNewHeads((header: any) => {
      console.log(`Chain is at block: #${header.number}`)
      setBlockNumber(header.number.toNumber())
    });
    setUnsub((_current) => _unsub) // To suppress `_unsub` being interpreted as an updater
  }, [api, isSubscribed, setIsSubscribed, setUnsub])

  useEffect(() => {
    if (!isSubscribed) {
      unsub()
      listenBlockHeader()
    }
  }, [listenBlockHeader, isSubscribed, unsub])
}
