import type { InjectedAccountWithMeta, InjectedExtension } from '@polkadot/extension-inject/types';
import type { KeyringPair } from '@polkadot/keyring/types';
import { useEffect, useState } from 'react';
import { atom, type SetterOrUpdater, useRecoilState } from 'recoil';
import { localStorageEffect } from './localStorageEffect';

export const currentAccounts = atom<InjectedAccountWithMeta[] | undefined>({
  key: 'currentAccounts',
  default: undefined,
})

export const currentSigner = atom<InjectedExtension | undefined>({
  key: 'currentSigner',
  default: undefined,
})

export const selectedAccount = atom<InjectedAccountWithMeta>({
  key: 'selectedAccount',
  default: undefined,
  effects: [
    localStorageEffect('selectedAccount')
  ],
})

export const selectedKeyringPair = atom<KeyringPair>(
  {
    key: 'selectedKeyringPair',
    default: undefined
  }
)

export const useSelectedAccount = () => {
  const [didMount, setDidMount] = useState(false);
  const [account, setSelectedAccount] = useRecoilState(selectedAccount)

  useEffect(() => {
    setDidMount(true)
  }, [])

  return [
    didMount ? account : undefined,
    setSelectedAccount
  ] as [undefined | InjectedAccountWithMeta, SetterOrUpdater<InjectedAccountWithMeta>]
}
