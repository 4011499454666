import { extendTheme } from '@chakra-ui/react'

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false
}

export const styles = {
  global: () => ({
    body: {
      bg: 'gray.800',
      color: 'white'
    }
  })
}

export const customTheme = extendTheme({
  config,
  styles,
})

export default customTheme
