import { Keyring } from '@polkadot/api'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useRecoilState } from 'recoil'
import { currentAccounts, currentSigner, selectedKeyringPair, useSelectedAccount } from 'state/accounts'
import { useApi } from '../useApi'

export const useFetchAccounts = () => {
  const [, setAccounts] = useRecoilState(currentAccounts)
  const [account, setAccount] = useSelectedAccount()
  const [signer, setSigner] = useRecoilState(currentSigner)
  const [, setKeyringPair] = useRecoilState(selectedKeyringPair)
  const isSetup = useRef(false)
  const api = useApi()
  const enableWeb3 = useCallback(async () => {
    // this call fires up the authorization popup
    const polkadotExtension = await import('@polkadot/extension-dapp')
    const { web3Accounts, web3Enable, web3FromSource } = polkadotExtension
    const extensions = await web3Enable('shiden-dex')

    if (extensions.length === 0) {
      // no extension installed, or the user did not accept the authorization
      // in this case we should inform the use and give a link to the extension
      console.error('error: Polkadot.js extension wallet is not installed')
      return
    }

    // we are now informed that the user has at least one extension and that we
    // will be able to show and use accounts
    const keyring = new Keyring({ type: 'sr25519' });
    const allAccounts = await web3Accounts()

    if (allAccounts.length === 0) {
      // if there is no activated account,
      // we should inform the user to activate their account
      console.error('error: There is no activated account')
      return
    }

    const selectedAccount = account ? account : allAccounts[0]
    const selectedKeyPair = keyring.addFromAddress(selectedAccount.address)
    const injector = await web3FromSource(selectedAccount.meta.source)

    setKeyringPair(selectedKeyPair)
    setAccount(selectedAccount)
    setAccounts(allAccounts)
    setSigner(injector)
  }, [account, setAccount, setAccounts, setKeyringPair, setSigner])

  const updateAccountInfo = useCallback(async () => {
    if (!account || !signer) return
    const polkadotExtension = await import('@polkadot/extension-dapp')
    const { web3FromSource } = polkadotExtension

    // we are now informed that the user has at least one extension and that we
    // will be able to show and use accounts
    const keyring = new Keyring({ type: 'sr25519' });
    const selectedKeyPair = keyring.addFromAddress(account.address)
    const injector = await web3FromSource(account.meta.source)

    setKeyringPair(selectedKeyPair)
    setSigner(injector)
  }, [account, setKeyringPair, setSigner])

  useEffect(() => {
    if (!isSetup.current && api) {
      isSetup.current = true
      enableWeb3()
    }
  }, [api, isSetup])

  useEffect(() => {
    updateAccountInfo()
  }, [account, updateAccountInfo])
}

export const useAccounts = () => {
  const [accounts] = useRecoilState(currentAccounts)
  const [account, setAccount] = useSelectedAccount()
  const [signer] = useRecoilState(currentSigner)
  const [keyringPair] = useRecoilState(selectedKeyringPair)

  return useMemo(() => ({
    account,
    accounts,
    signer,
    keyringPair,
    setAccount,
  }), [account, accounts, signer, keyringPair, setAccount])
}
