import { atom, useRecoilState } from 'recoil'

export const currentBlockNumber = atom<number>({
  key: 'currentBlockNumber',
  default: 0,
})

type Result = [number, (arg: number) => void]

export const useBlockNumber = (): Result => {
  const [blockNumber, setBlockNumber] = useRecoilState(currentBlockNumber)

  return [
    blockNumber,
    setBlockNumber
  ]
}
