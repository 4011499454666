import { chainIdByName } from '@/constants/chainIdByName'

export const wsProviderInfoByChainId = {
  [chainIdByName.shibuya]: 'wss://rpc.shibuya.astar.network',
  [chainIdByName.shiden]: 'wss://shiden.api.onfinality.io/public-ws'
} as const

export const wsProviderInfo = {
  shibuya: 'wss://rpc.shibuya.astar.network',
  shiden: 'wss://shiden.api.onfinality.io/public-ws'
} as const

export type wsProvidersKey = keyof typeof wsProviderInfo

export const wsProviderByChainId = {
  [chainIdByName.shibuya]: wsProviderInfo.shibuya,
  [chainIdByName.shiden]: wsProviderInfo.shiden
} as const
