import { ApiPromise } from '@polkadot/api';
import { atom, type SetterOrUpdater, useRecoilState } from 'recoil'
import { localStorageEffect } from './localStorageEffect';
import { useEffect, useState } from 'react';
import { chainIdByName } from '@/constants/chainIdByName';

export const currentAPI = atom<ApiPromise | null>({
  key: 'currentAPI',
  default: null,
});

export const currentChainID = atom<number | undefined>({
  key: 'currentChainID',
  default: chainIdByName.shibuya,
  effects: [
    localStorageEffect('currentChainID')
  ]
})

export const useChainIdByProvider = () => {
  const [didMount, setDidMount] = useState(false);
  const [chainId, setChainId] = useRecoilState(currentChainID)

  useEffect(() => {
    setDidMount(true)
  }, [])

  return [
    didMount ? chainId : undefined,
    setChainId
  ] as [undefined | number, SetterOrUpdater<number>]
}
