import { useFetchAccounts } from '@/hooks/polkadotExtension/useAccounts'
import { useFetchApi } from '@/hooks/useApi'
import { useSubscribeBlockNumber } from '@/hooks/utils/useSubscribeBlockNumber'

export const CommonDataFetcher = () => {
  // this Component is used to fetch necessary data to interact with contract or rpc to reduce network request
  const api = useFetchApi()
  useSubscribeBlockNumber()
  useFetchAccounts()
  return null
}
